import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default [
  {
    id: 1,
    username: "",
    password: "",
    email: "",
    accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
    refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
    roles: [1], // Administrator
    pic: toAbsoluteUrl("/media/users/300_21.jpg"),
    fullname: "",
    firstname: "",
    lastname: "",
    occupation: "",
    companyName: "",
    phone: "",
    language: "en",
    timeZone: "",
    website: "",
    emailSettings: {
      emailNotification: true,
      sendCopyToPersonalEmail: false,
      activityRelatesEmail: {
        youHaveNewNotifications: false,
        youAreSentADirectMessage: false,
        someoneAddsYouAsAsAConnection: true,
        uponNewOrder: false,
        newMembershipApproval: false,
        memberRegistration: true,
      },
      updatesFromKeenthemes: {
        newsAboutKeenthemesProductsAndFeatureUpdates: false,
        tipsOnGettingMoreOutOfKeen: false,
        thingsYouMissedSindeYouLastLoggedIntoKeen: true,
        newsAboutMetronicOnPartnerProductsAndOtherServices: true,
        tipsOnMetronicBusinessProducts: true,
      },
    },
    communication: {
      email: true,
      sms: true,
      phone: false,
    },
    address: {
      addressLine: "",
      city: "",
      state: "",
      postCode: "",
    },
    socialNetworks: {
      linkedIn: "",
      facebook: "",
      twitter: "",
      instagram: "",
    },
  },
];
