import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Payment from "../../../../UserProfile/stripe/Payment";
import {
  ModelStateMessage,
  apiCalll,
} from "../../../../../../_metronic/_helpers/apiHelpers";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import MonerisModal from "./MonerisModal";

const Schema = Yup.object().shape({
  cardName: Yup.string().required("Card name is required"),
});

const PayInvoice = ({
  isPaying,
  setIsPaying,
  showCard,
  setHidden,
  setShowPay,
  setShowAddCard,
  setHiddenCard,
  startDate,
  endDate,
  id,
  setShowBtnPay,
  amount,
  paySelected,
  ids,
  extra,
  adjustmentDescription,
  setShowExtra,
  setInvoiceNumber,
  selectedCreditMemo,
  setIds,
  downloadBillAsPDF
}) => {
  const [Billing, setbilling] = useState({
    methodPayment: "",
    cardToken: "",
    cardId: "",
    cardTokenId: null,
    cardName: "",
    cardNumber: "",
    cvv: "",
    stripeCardId: null,
    creditMemoIds: selectedCreditMemo
  });
  const history = useHistory();
  const [clientSecret, setclientSecret] = useState("");
  const [Key, setKey] = useState(null);
  const [showMoneris, setShowMoneris] = useState(false);
  const [cards, setCards] = useState([]);
  const [moneris, setMoneris] = useState([]);
  const [banks, setBanks] = useState([]);
  const [useMonerisCards, setUseMonerisCards] = useState(false);
  const [useStripeCards, setUseStripeCards] = useState(false);
  const [useStripeAcss, setUseStripeAcss] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const GetKeys = () => {
    apiCalll({
      method: "GET",
      link: `/Pharmacies/CreateSetupIntent`,
    }).then(async (result) => {
      try {
        if (result[0] === 200) {
          setclientSecret(result[1]?.clientSecret);
          setKey(result[1]?.stripePublishableKey);
        }
      } catch (err) {}
    });
  };
  const GetCards = () => {
    setIsFetching(true);
    apiCalll({
      method: "GET",
      link: `/Pharmacies/GetPharmacyCards?pharmacyId=${id}`,
    }).then(async (result) => {
      try {
        if (result[0] === 200) {
          setCards(result[1]?.stripeCards);
          setMoneris(result[1]?.monerisCards)
          setBanks(result[1]?.stripeAcss);
          setUseMonerisCards(result[1]?.useMonerisCards);
          setUseStripeCards(result[1]?.useStripeCards);
          setUseStripeAcss(result[1]?.useStripeAcss);

        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsFetching(false);
      }
    });
  };
  // const DeleteCards = (id) => {
  //   apiCalll({
  //     method: "GET",
  //     link: `/Pharmacies/DeleteCard?cardId=${id}`,
  //   }).then(async (result) => {
  //     try {
  //       if (result[0] === 200) {
  //         GetCards();
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success",
  //           text: "The card has been deleted successfully!",
  //         });
  //       }
  //     } catch (err) {}
  //   });
  // };

  function getTimeZoneOffset() {
    const offsetInMinutes = new Date().getTimezoneOffset();

    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const sign = offsetInMinutes < 0 ? "+" : "-";

    return `${sign}${hours.toString().padStart(2, "")}`;
  }

  function handleSubmit(data) {
    setIsPaying(true);
    if (paySelected === true) {
      data.ids = ids;
    }
    data.extra = extra || 0
    data.adjustmentDescription = adjustmentDescription
    return apiCalll({
      method: "Post",
      link:
        paySelected === true
          ? `/Pharmacies/ChargeSelected/${id}`
          : `/Pharmacies/ChargeByDate?pharmacyId=${id}&startDate=${startDate ||
              ""}&endDate=${endDate || ""}&timezone=${getTimeZoneOffset()}`,
      data: data,
    })
      .then((res) => {
        if (res[0] === 200) {
          document.getElementById("refresh_invoices").click();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "The payment has been paid successfully!",
          });
          setShowPay(false);
          setShowAddCard(false);
          setHidden(false);
          setShowBtnPay(true);
          setShowExtra(false)
          setInvoiceNumber(res[1]?.invoiceNumber)
          setShowMoneris(false)
          setIds([])
          downloadBillAsPDF()
        } else if (res[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        } else {
          ModelStateMessage(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsPaying(false);
      });
  }

  useEffect(() => {
    GetKeys();
    GetCards();
  }, []);

  const formik = useFormik({
    initialValues: Billing,
    validationSchema: Schema,
    onSubmit: async (values) => {
      console.log(values);
      document.getElementById("Strip_billing_pay").click();
    },
  });

  function ImageCardNumber({ value }) {
    return (
      <img
        width={50}
        height={30}
        src={toAbsoluteUrl(
          value === "visa" || value === "v"
            ? "/media/svg/card-logos/visa.svg"
            : value === "mastercard" || value === "m"
            ? "/media/svg/card-logos/mastercard.svg"
            : value === "american express" || value === "ax" || value === "amex"
            ? "/media/svg/card-logos/american-express.svg"
            : value === "discover" || value === "no"
            ? "/media/svg/card-logos/discover.svg"
            : value === "jcb" || value === "c1"
            ? "/media/svg/card-logos/jcb.svg"
            : value === "diners club"
            ? "/media/svg/card-logos/diners-club.svg"
            : value === "unionpay"
            ? "/media/svg/card-logos/unionpay.svg"
            : value === "sears" || value === "se"
            ? "/media/svg/card-logos/sears.svg"
            : !!value === false
            ? "/media/svg/card-logos/debit.svg"
            : value === "uatp"
            ? "/media/svg/card-logos/uatp.svg"
            : ""
        )}
        alt=""
        className="mr-3"
      />
    );
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  return (
    <>
      <div className="card border-0">
        <div className="card-body">
          <div className="row d-flex justify-content-center pb-5">
            <div className="col-md-8 mb-4 mb-md-0">
              <div className="pt-2">
                <div className="d-flex pb-2 justify-content-between pb-10">
                  <div>
                    <p>
                      <b>
                        Subtotal:{" "}
                        <span className="text-info ml-3">
                          {formatCurrency(amount)}
                        </span>
                      </b>
                    </p>
                  </div>
                </div>
                <div className="separator separator-dashed"></div>
               {!isFetching ? <>
                {useMonerisCards && 
                <>
                <div className="pt-5 d-flex align-items-center">
                  <h5 className="font-weight-bold text-muted mr-3">Moneris</h5>
                  <div
                    className="mt-2 cursor-pointer"
                    onClick={() => {
                      setHiddenCard(true);
                      setShowMoneris(true);
                    }}
                  >
                  <OverlayTrigger
                    overlay={
                      <Tooltip
                            style={{ pointerEvents: "none" }}
                            id="products-delete-tooltip"
                          >
                            Add payment method
                          </Tooltip>
                        }
                      >
                      <p className="text-primary cursor-pointer">
                        <i className="fas fa-plus-circle text-primary pr-2" />
                      </p>
                  </OverlayTrigger>
                  </div>
                </div>
                  {
                moneris.length > 0 ?
                moneris.map((card, index) => (
                  <div key={index}>
                    <label
                      htmlFor={`cardRadio-${index}`}
                      className="w-100 cursor-pointer"
                    >
                      <div className="py-3 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <>
                          <div className="btn btn-sm btn-icon text-secondary mr-5">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Plus.svg"
                            )}
                            className="w-40px mr-3"
                            alt=""
                          />
                        </div>
                          </>
                          <ImageCardNumber value={card?.brand?.toLowerCase()} />
                          <div className="me-3">
                            <div className="d-flex align-items-center">
                              <h5 className="font-weight-bold p-0 m-0">
                                {card.brand}
                              </h5>

                              <div className="badge badge-light-danger ml-5">
                                <h5 className="font-weight-bold p-0 m-0">
                                  ****{card.last4Digits}
                                </h5>
                              </div>
                            </div>
                            <div className="text-muted">
                              Expires {card.expiryMonth}/{card.expiryYear}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-3 ms-9">
                          <label className="form-check form-check-custom form-check-solid mx-5">
                            <input
                              id={`cardRadio-${index}`}
                              className="form-check-input cursor-pointer px-4"
                              type="radio"
                              name="card"
                              checked={Billing.stripeCardId === card.id}
                              onChange={() =>
                                setbilling({
                                  ...Billing,
                                  cardToken: "",
                                  cardId: "",
                                  methodPayment: "moneris",
                                  cardTokenId: null,
                                  stripeCardId: card.id,
                                })
                              }
                            />
                          </label>
                        </div>
                      </div>
                      <div className="separator separator-dashed"></div>
                    </label>
                  </div>
                ))
                : <div className="d-flex justify-content-center my-10 text-muted">
                 <h3>No cards found</h3>
                </div>
                }
                </>}
                {useStripeCards && <>
                <div className="pt-5 d-flex align-items-center">
                  <h5 className="font-weight-bold text-muted mr-3">Stripe</h5>
                  <div
                    className="mt-2 cursor-pointer"
                    onClick={() => {
                      setHiddenCard(true);
                      setShowAddCard(true);
                      setbilling({
                        ...Billing,
                        cardToken: "",
                        cardId: "",
                        cardTokenId: 0,
                        methodPayment: "card",
                        stripeCardId: "",
                      });
                    }}
                  >
                 <OverlayTrigger
                    overlay={
                      <Tooltip
                            style={{ pointerEvents: "none" }}
                            id="products-delete-tooltip"
                          >
                            Add payment method
                          </Tooltip>
                        }
                      >
                      <p className="text-primary cursor-pointer">
                        <i className="fas fa-plus-circle text-primary pr-2" />
                      </p>
                  </OverlayTrigger>
                  </div>
                </div>
                {
                  cards.length > 0 ?
                cards.map((card, index) => (
                  <div key={index}>
                    <label
                      htmlFor={`stripeCard-${index}`}
                      className="w-100 cursor-pointer"
                    >
                      <div className="py-3 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <>
                          <div className="btn btn-sm btn-icon text-secondary mr-5">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Plus.svg"
                            )}
                            className="w-40px mr-3"
                            alt=""
                          />
                        </div>
                          </>
                          <ImageCardNumber value={card?.brand?.toLowerCase()} />
                          <div className="me-3">
                            <div className="d-flex align-items-center">
                              <h5 className="font-weight-bold p-0 m-0">
                                {card.brand}
                              </h5>

                              <div className="badge badge-light-danger ml-5">
                                <h5 className="font-weight-bold p-0 m-0">
                                  ****{card.last4Digits}
                                </h5>
                              </div>
                            </div>
                            <div className="text-muted">
                              Expires {card.expiryMonth}/{card.expiryYear}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-3 ms-9">
                          <label className="form-check form-check-custom form-check-solid mx-5">
                            <input
                              id={`stripeCard-${index}`}
                              className="form-check-input cursor-pointer px-4"
                              type="radio"
                              name="card"
                              checked={Billing.stripeCardId === card.id}
                              onChange={() =>
                                setbilling({
                                  ...Billing,
                                  cardToken: "",
                                  cardId: "",
                                  methodPayment: "card",
                                  cardTokenId: null,
                                  stripeCardId: card.id,
                                })
                              }
                            />
                          </label>
                        </div>
                      </div>
                      <div className="separator separator-dashed"></div>
                    </label>
                  </div>
                ))
                : <div className="d-flex justify-content-center my-10 text-muted">
                 <h3>No cards found</h3>
                </div>
                }
                </>}
                {useStripeAcss && <>
                <div className="pt-5 my-3 d-flex align-items-center">
                  <h5 className="font-weight-bold text-muted mr-3">
                    Stripe Acss Debit
                  </h5>
                </div>
                {
                  banks.length > 0 ?
                banks.map((bank, index) => (
                  <div key={index}>
                    <label
                      htmlFor={`acss_debit-${index}`}
                      className="w-100 cursor-pointer"
                    >
                      <div className="py-3 d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <>
                          <div className="btn btn-sm btn-icon text-secondary mr-5">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Plus.svg"
                            )}
                            className="w-40px mr-3"
                            alt=""
                          />
                        </div>
                          </>
                          <i class="fas fa-university mr-5 fa-2x"></i>
                          <div className="me-3">
                            <div className="d-flex align-items-center">
                              <h5 className="font-weight-bold p-0 m-0">
                                {bank.acssDebit.bankName}
                              </h5>

                              <div className="badge badge-light-danger ml-5">
                                <h5 className="font-weight-bold p-0 m-0">
                                  ****{bank.acssDebit.last4}
                                </h5>
                              </div>
                            </div>
                            {/* <div className="text-muted">
                              Expires {card.expiryMonth}/{card.expiryYear}
                            </div> */}
                          </div>
                        </div>
                        <div className="d-flex my-3 ms-9">
                          <label className="form-check form-check-custom form-check-solid mx-5">
                            <input
                              id={`acss_debit-${index}`}
                              className="form-check-input cursor-pointer px-4"
                              type="radio"
                              name="card"
                              checked={Billing.cardId === bank.id}
                              onChange={() =>
                                setbilling({
                                  ...Billing,
                                  cardToken: "",
                                  cardId: bank.id,
                                  methodPayment: "acss_debit",
                                  cardTokenId: null,
                                  stripeCardId: ""
                                })
                              }
                            />
                          </label>
                        </div>
                      </div>
                      <div className="separator separator-dashed"></div>
                    </label>
                  </div>
                ))
                : <div className="d-flex justify-content-center my-10 pt-3 text-muted">
                 <h3>No banks found</h3>
                 </div>
                }
                </>}
                  <h5 className="font-weight-bold text-muted py-5">Other</h5>
                <label htmlFor={`check`} className="w-100 cursor-pointer">
                  <div className="py-3 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="btn btn-sm btn-icon text-secondary mr-5">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Plus.svg"
                          )}
                          className="w-40px mr-3"
                          alt=""
                        />
                      </div>
                      <i class="fas fa-money-check mr-5 fa-2x"></i>
                      <div className="me-3">
                        <div className="d-flex align-items-center">
                          <h5 className="font-weight-bold">Check</h5>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex my-3 ms-9">
                      <label className="form-check form-check-custom form-check-solid mx-5">
                        <input
                          id="check"
                          className="form-check-input"
                          type="radio"
                          name="check"
                          checked={Billing.methodPayment === "check"}
                          onChange={() =>
                            setbilling({
                              ...Billing,
                              cardToken: "",
                              cardId: "",
                              cardTokenId: 0,
                              methodPayment: "check",
                              stripeCardId: null,
                            })
                          }
                        />
                      </label>
                    </div>
                  </div>
                </label>
                <div className="separator separator-dashed"></div>
                <label htmlFor={`cash`} className="w-100 cursor-pointer">
                  <div className="py-3 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="btn btn-sm btn-icon text-secondary mr-5">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Plus.svg"
                          )}
                          className="w-40px mr-3"
                          alt=""
                        />
                      </div>
                      <i class="fas fa-money-bill-alt mr-5 fa-2x"></i>
                      <div className="me-3">
                        <div className="d-flex align-items-center ">
                          <h5 className="font-weight-bold">Cash</h5>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex my-3 ms-9">
                      <label className="form-check form-check-custom form-check-solid mx-5">
                        <input
                          id="cash"
                          className="form-check-input"
                          type="radio"
                          name="cash"
                          checked={Billing.methodPayment === "cash"}
                          onChange={() =>
                            setbilling({
                              ...Billing,
                              cardToken: "",
                              cardId: "",
                              cardTokenId: 0,
                              methodPayment: "cash",
                              stripeCardId: null,
                            })
                          }
                        />
                      </label>
                    </div>
                  </div>
                </label>
                <div className="separator separator-dashed"></div>
                <label
                  htmlFor={`bank_transfer`}
                  className="w-100 cursor-pointer"
                >
                  <div className="py-3 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="btn btn-sm btn-icon text-secondary mr-5">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Plus.svg"
                          )}
                          className="w-40px mr-3"
                          alt=""
                        />
                      </div>
                      <img 
                      src={toAbsoluteUrl("/media/icons/bank-transfer.png")}
                      className="w-35px mr-3"
                      alt=""
                       />
                      <div className="me-3">
                        <div className="d-flex align-items-center">
                          <h5 className="font-weight-bold">Bank Transfer</h5>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex my-3 ms-9">
                      <label className="form-check form-check-custom form-check-solid mx-5">
                        <input
                          id="bank_transfer"
                          className="form-check-input"
                          type="radio"
                          name="bank_transfer"
                          checked={Billing.methodPayment === "bank transfer"}
                          onChange={() =>
                            setbilling({
                              ...Billing,
                              cardToken: "",
                              cardId: "",
                              cardTokenId: 0,
                              methodPayment: "bank transfer",
                              stripeCardId: "",
                            })
                          }
                        />
                      </label>
                    </div>
                  </div>
                </label>
                <div className="separator separator-dashed"></div>
                {useStripeAcss && <label
                  htmlFor={`send`}
                  className="w-100 cursor-pointer"
                >
                  <div className="py-3 d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="btn btn-sm btn-icon text-secondary mr-5">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Plus.svg"
                          )}
                          className="w-40px mr-3"
                          alt=""
                        />
                      </div>
                      <i className="fab fa-cc-stripe mr-5 fa-2x"></i>
                      <div className="me-3">
                        <div className="d-flex align-items-center">
                          <h5 className="font-weight-bold">
                            Pre-Authorized Debit
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex my-3 ms-9">
                      <label className="form-check form-check-custom form-check-solid mx-5">
                        <input
                          id="send"
                          className="form-check-input"
                          type="radio"
                          name="send"
                          checked={Billing.methodPayment === "send"}
                          onChange={() =>
                            setbilling({
                              ...Billing,
                              cardToken: "",
                              cardId: "",
                              cardTokenId: 0,
                              methodPayment: "send",
                              stripeCardId: "",
                            })
                          }
                        />
                      </label>
                    </div>
                  </div>
                </label>}
               </> :
               <div className="d-flex justify-content-center my-20 py-20">
                  Please wait...
                </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="my-1 me-5">
          <button
            hidden={true}
            id="proceed_to_payment"
            type="button"
            className="btn btn-primary my-1 me-12"
            disabled={isPaying}
            onClick={() => {
              if (Billing.methodPayment === "") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Please select payment method!",
                });
                return;
              }
              handleSubmit(Billing);
            }}
          >
            {isPaying ? (
              <div className="d-flex align-items-center">
                Procceding to payment...
                <span className="spinner-border spinner-border-sm ml-3"></span>
              </div>
            ) : (
              "Proceed to payment"
            )}
          </button>
        </div>
      </div>
      <Modal
        size="lg"
        show={showCard}
        onHide={() => {
          setHiddenCard(false);
          setShowAddCard(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bolder">Pay Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <label>
              Name on card<span className="text-danger">*</span>
            </label>
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              type="text"
              className="form-control  form-control-md"
              placeholder="Enter card name"
              name="cardName"
              value={Billing.cardName}
              onChange={(e) => {
                setbilling({
                  ...Billing,
                  cardName: e.target.value,
                  methodPayment: "card",
                });
                formik.setFieldValue("cardName", e.target.value);
              }}
            />
            <div className="text-primary">
              {formik.errors.cardName && formik.touched.cardName ? (
                <div className="text-danger mt-2">
                  <span className="p-2">{formik.errors.cardName}</span>
                </div>
              ) : null}
            </div>
          </div>
          {clientSecret && Key && (
            <Payment
              handleSubmit={(data) => {
                handleSubmit(data);
              }}
              Billing={Billing}
              setbilling={setbilling}
              publishedKey={Key}
              formik={formik}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            disabled={isPaying}
            variant="secondary"
            onClick={() => {
              setHiddenCard(false);
              setShowAddCard(false);
              setbilling({
                ...Billing,
                cardName: "",
                cardToken: "",
                cardId: "",
                cardTokenId: 0,
                methodPayment: "",
                stripeCardId: null,
              });
            }}
          >
            Cancel
          </Button>
          <div className="my-1 me-5">
            <button
            id="proceed_to_payment"
              onClick={() => {
                formik.handleSubmit();
              }}
              type="button"
              className="btn btn-primary my-1 me-12"
              disabled={isPaying}
            >
              {isPaying ? (
                <div className="d-flex align-items-center">
                  Procceding to payment...
                  <span className="spinner-border spinner-border-sm ml-3"></span>
                </div>
              ) : (
                "Proceed to payment"
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <MonerisModal
        show={showMoneris}
        setShow={setShowMoneris}
        setHiddenCard={setHiddenCard}
        Billing={Billing}
        setbilling={setbilling}
        isPaying={isPaying}
        pharmacyId={id}
        />
    </>
  );
};

export default PayInvoice;
