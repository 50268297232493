/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo , useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Modal, ProgressBar } from "react-bootstrap";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const token = JSON.parse(localStorage.getItem("user"))?.token;
const Username = localStorage.getItem("UserEmail");

export function QuickUserToggler({ menu }) {
  // const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handelQuickUser = async () => {
    handleShow();
  };
  const history = useHistory();
  const LoginBussnisAdmin = async (values) => {

    await fetch(`${BASE_URL}/Account/Token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify({
        username: Username,
        password: values.password,
      }),
      Allow: "POST",
    })
      .then(async (response) => {
        const statusCode = response.status;
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          console.log(data[1],data[1]?.profilePicture?.toString(),data[1]?.profilePicture)
          console.log(JSON.stringify(data[1]))
          localStorage.removeItem("user");
          localStorage.removeItem("ProfilePicture");
           localStorage.removeItem("IconShow");
          localStorage.removeItem("dateFormat");

          localStorage.setItem("user", JSON.stringify(data[1]));
          localStorage.setItem(
            "ProfilePicture",
            JSON.stringify(data[1].profilePicture)
          );
          localStorage.setItem(
            "dateFormat",
            data[1]?.dateFormat !== null &&
              data[1]?.dateFormat !== undefined &&
              data[1]?.dateFormat !== ""
              ? data[1]?.dateFormat
              : "DD/MM/YYYY"
          );
          const user = {
            accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
            refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
          };
          setTimeout(() => {
            history.push("/dashboard");
            window.location.reload();
          }, 1000);
          if (user) {
            return [200, { ...user, password: undefined }];
          }
        }  else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[1].message,
          });

          return [400];
        } else if (data[0] === 400 && data[1].errors) {
          for (const [key, value] of Object.entries(data[1].errors)) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: value,
            });
          }

          return [400];
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });

        return [400];
      });
  };
  return (
    <>
      <div className="mb-5">
        <div className="nav-item Settings">
      {layoutProps.offcanvas && (
        menu.map((item, index) => {
          if(item.position < 0)
          return (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="metronic-features">
                {item.name}
              </Tooltip>}
            >
              <Link
              id={`${item?.name}`}
                to={item.link}
                className={`nav-link btn btn-icon btn-clean btn-lg`}
                data-toggle="tab"
                role="tab"
              >
                <span className="svg-icon svg-icon-lg">
                  <SVG
                    src={toAbsoluteUrl(
                      item.icon
                    )}
                  />
                </span>
              </Link>
            </OverlayTrigger>
          )
        })
      )}
    </div>
      <div className="nav-item my-5 UserProfile">
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="quick-user-tooltip">User Profile</Tooltip>}
        >
          <a
            className="btn btn-icon btn-clean btn-lg w-40px h-40px "
            id="kt_quick_user_toggle"
            data-placement="right"
            data-container="body"
            data-boundary="window"
          >
            <span className="symbol symbol-30 symbol-lg-40">
              <span className="svg-icon svg-icon-lg">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
            </span>
          </a>
        </OverlayTrigger>
      )}
      </div>
      <>
            {localStorage.getItem("IconShow") === "true" ? (
              <div className="nav-item ">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="quick-user-tooltip">Cancel Login</Tooltip>
                  }
                >
                  <div
                    className={"nav-link btn btn-icon btn-clean btn-lg"}
                    id="kt_quick_user_toggle"
                    data-placement="right"
                    data-container="body"
                    data-boundary="window"
                    onClick={() => {
                       handelQuickUser();
                    }}
                  >
                    <span className="symbol symbol-30 symbol-lg-40">
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Sign-out.svg"
                          )}
                        />
                      </span>
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
            ) : (
              ""
            )}
          </>
          </div>
          <Modal
        size="md"
        show={show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && (
          <ProgressBar
            variant={"success"}
            animated
            now={100}
            style={{ height: "3px", width: "100%" }}
          />
        )}
        <Formik
          enableReinitialize={true}
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={(values) => {
            LoginBussnisAdmin(values);
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <>
              <>
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Login : {Username}
                  </Modal.Title>
                </Modal.Header>
              </>
              <Modal.Body className="overlay overlay-block cursor-default">
                {isLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">

                  <label>
                    {"Password"} <span className="text-primary">*</span>
                  </label>
                  <div className="d-flex align-items-center">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder={"New password"}
                      value={values.password}
                      autoFocus={true}
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className="svg-icon svg-icon-md svg-icon-primary position-absolute right-0 mr-5 pr-3"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          showPassword
                            ? "/media/svg/icons/General/Visible.svg"
                            : "/media/svg/icons/General/Hidden.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                  <div className="text-primary">
                    <ErrorMessage name="newPassword" />
                  </div>


                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button

                  type="button"
                  onClick={handleClose}
                  className="btn btn-light btn-elevate"
                >
                  {"Cancel"}
                </button>
                <> </>
                <button
                  disabled={!values.password ? true : false}
                  type="submit"
                  onClick={() => handleSubmit()}

                  className="btn btn-primary btn-elevate outline-primary"

                >
                  {"Login"}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
