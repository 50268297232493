import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function ErrorPage401() {
  return (
    <div className="d-flex flex-column flex-root">
      
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/401.svg")})`, backgroundPosition: 'top', backgroundSize: 'auto'
        }}
      >
        <div classname="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
        style={{position:"absolute", top:"0", right:"0",marginTop:"1.25rem",padding:"2.5rem 1.25rem",fontWeight:"bolder",fontSize:"18px"}}>
        <Link
          classname="font-weight-bold ml-2"
          id="kt_login_signup"
          to="/logout"
        >
          Sign In!
        </Link>
      </div>
        <Link
          to={"/dashboard"}
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "70px", position: 'absolute', left: '53%', bottom: '43%' }}
        >
          Go Back
        </Link>
      </div>
    </div>
  );
}
